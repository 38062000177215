#gradient-canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width:100%;
    height:100%;
    --gradient-color-1: #03002E; 
    --gradient-color-2: #010048;
    --gradient-color-3: #010048;
    /* --gradient-color-4: #02006c;         */
}