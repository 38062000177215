@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-216px * 12))}    
}

.slider {
   width: 100%;
   overflow-x: hidden;   
}

.slide-track {
    display: flex;
    animation: scroll 30s infinite linear;
    height: 100%;
    align-items: center;
}

.slider::before, slider::after {
    content: "";
    position: absolute;
    width: 48%;
    z-index: 2;
} 

.slider::before {
    left: 0;
	top: 0;
}

.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}
