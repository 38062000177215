
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=MuseoModerno&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@layer components {
  .header {
    @apply font-museomoderno text-light-green font-bold mb-1;
  }

  .subtitle {
    @apply text-3xl font-museomoderno text-light-green mb-1;
  }

  .description {
    @apply text-base font-montserrat text-light-green;
  }

  .logo {
    @apply h-6 w-6 mr-6
  }
}

body {
  margin: 0;  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #041200;
  overflow-x: scroll;  
}